import { Injectable } from '@angular/core';

import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { tap } from 'rxjs';
import { orderBy } from 'lodash';

import {
  CheckItemAvailability,
  GetHomePageReviews,
  GetVenueConfig,
  GetVenueMenusAvailability,
  SetFilteringActivitiesValue,
  SetFilteringGalleryImageValue,
} from './venue.actions';
import { ClearCart } from 'src/app/_shared/_ngxs/cart.actions';
import { UpdateVenueOrderSettings } from './venue-order-settings.actions';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';
import {
  HideSpinner,
  ShowSpinner,
} from 'src/app/_shared/_components/spinner/spinner.state';

import { DeliveryMethod, OrderType } from '../_enums/order.enum';
import { NewsPageDataType } from '../../venue/news/_enums/news-page-data.enum';

import {
  ItemIds,
  ItemUnavailabilityReason,
  UnavailableItemsIdsWithReason,
  ItemWithMenuAndSection,
  Website,
  Item,
} from 'src/app/_shared/_interfaces/item.model';
import {
  Activity,
  NewsPageData,
} from 'src/app/_shared/_interfaces/news-page.model';
import { Menu, VisibleMenu } from 'src/app/_shared/_interfaces/menu.model';
import {
  GalleryImage,
  GalleryPageData,
} from 'src/app/_shared/_interfaces/gallery.model';
import { Price } from '../_models/currency.model';
import {
  MenuSchedule,
  QRSettings,
  QRSettingsType,
} from '../_interfaces/qrSettings.model';
import { HomePage } from 'src/app/_shared/_interfaces/home-page.model';
import { VisibleMenuAvailability } from '../../venue/dine-in/_models/menus.models';
import { CwSettings } from '../_interfaces/cwSettings.model';
import {
  Address,
  OperatingHours,
} from 'src/app/_shared/_models/common.interface';

import { MenusService } from 'src/app/_services/menus.service';
import { CatalogService } from 'src/app/_services/catalog.service';
import { VenueConfigService } from 'src/app/_services/venue-config.service';
import { ReviewsService } from 'src/app/_services/reviews.service';
import {
  copy,
  getItemIdsInMenus,
  getPageData,
} from 'src/app/_shared/_utils/common';
import { isCurrentPeriodOfTime } from 'src/app/profile/_helpers/default-menu.helpers';
import {
  getAvailableItemsList,
  getMenusPrepared,
  getVisibleMenus,
} from '../_utils/menus';

const PRODUCTION_VENUE_NAMES = ['la-boheme'];
const EARLY_ADOPTERS_VENUE_NAMES = [
  'zaya-thai-pantry-llc',
  'zaya-thai-pantry-',
  'zaya-thai-pantry',
];

export interface VenueStateModel {
  data: any;
  config: any;
  orderTypes: string[] | null;
  homePage: any;
  menuPage: any;
  aboutPage: any;
  contactPage: any;
  galleryPage: GalleryPageData | null;
  newsPage: NewsPageData | null;
  menus: Menu[];
  visibleMenus: Menu[];
  visibleMenusIds: string[];
  availableVisibleMenusItems: Item[];
  unavailableItems: ItemWithMenuAndSection[];
  unavailableItemsIdsWithReason: UnavailableItemsIdsWithReason;
  websites: Website[];
  qrConfig: Record<QRSettingsType, QRSettings> | null;
  orderMinimum: Price | null;
  cwName: string;
}

const defaults = {
  data: null,
  config: null,
  orderTypes: null,
  homePage: null,
  menuPage: null,
  aboutPage: null,
  contactPage: null,
  galleryPage: null,
  newsPage: null,
  unavailableItems: [],
  unavailableItemsIdsWithReason: {},
  visibleMenusIds: [],
  availableVisibleMenusItems: [],
  menus: [],
  visibleMenus: [],
  websites: [],
  qrConfig: null,
  orderMinimum: null,
  cwName: '',
};

@State<VenueStateModel>({
  name: 'venue',
  defaults,
})
@Injectable()
export class VenueState {
  constructor(
    private readonly service: VenueConfigService,
    private readonly menusService: MenusService,
    private readonly catalogService: CatalogService,
    private readonly reviewsService: ReviewsService,
    private readonly store: Store
  ) {}

  @Selector()
  static orderOptions({
    data: {
      venue: { orderOptions },
    },
  }: VenueStateModel):
    | DeliveryMethod.Delivery
    | DeliveryMethod.Takeout
    | DeliveryMethod.Curbside {
    return orderOptions;
  }

  @Selector()
  static cwSettings({ data }: VenueStateModel): CwSettings {
    return data.venue.cwSettings;
  }

  @Selector()
  static allowOpenTab({ data }: VenueStateModel): boolean {
    return data.venue.allowOpenTab;
  }

  @Selector()
  static openingHours({ data: { venue } }: VenueStateModel): OperatingHours[] {
    return venue?.openingHours || [];
  }

  @Selector()
  static qrConfig({
    qrConfig,
  }: VenueStateModel): Record<QRSettingsType, QRSettings> | null {
    return qrConfig;
  }

  static defaultMenuId(currentSettingType: QRSettingsType) {
    return createSelector(
      [VenueState],
      ({ qrConfig, menuPage }: VenueStateModel) => {
        const visibleMenus: VisibleMenu[] = menuPage?.visibleMenus;
        const firstVisibleMenuId = visibleMenus[0]?.id || '';
        if (!qrConfig || !currentSettingType || currentSettingType === 'menu') {
          return firstVisibleMenuId;
        }

        const menuSchedule: MenuSchedule[] | undefined =
          qrConfig[currentSettingType]?.menuSchedule;
        const currentMenuId = menuSchedule?.find(
          schedule =>
            isCurrentPeriodOfTime(schedule) &&
            !!visibleMenus.find(menu => menu.id === schedule.menuId)
        )?.menuId;

        return currentMenuId
          ? currentMenuId || firstVisibleMenuId
          : firstVisibleMenuId;
      }
    );
  }

  @Selector()
  static config({ config }: VenueStateModel): any {
    return config;
  }

  @Selector()
  static template({ config }: VenueStateModel): any {
    return config.template;
  }

  @Selector()
  static primaryColor({ config }: VenueStateModel): string {
    return config.primaryColor;
  }

  @Selector()
  static orderTypes({ orderTypes }: VenueStateModel): string[] {
    return orderTypes || [];
  }

  @Selector()
  static selectableDeliveryTypes({ orderTypes }: VenueStateModel): string[] {
    return (orderTypes || []).filter(type => type !== OrderType.DineIn);
  }

  @Selector()
  static websites({ websites }: VenueStateModel): Website[] {
    return websites;
  }

  @Selector()
  static homePage({ homePage }: VenueStateModel): HomePage {
    return homePage;
  }

  @Selector()
  static menuPage({ menuPage }: VenueStateModel): {
    visibleMenus: VisibleMenu[];
  } {
    return menuPage;
  }

  @Selector()
  static bannerImage({ menuPage }: VenueStateModel): string {
    return menuPage.bannerImage;
  }

  @Selector()
  static cwName({ cwName }: VenueStateModel): string {
    return cwName;
  }

  static visibleItems() {
    return createSelector([VenueState], ({ visibleMenus }: VenueStateModel) => {
      const items: Item[] = [];
      visibleMenus?.forEach((menu: Menu) => {
        const menusService: MenusService = new MenusService();
        const menuAvailability: VisibleMenuAvailability | null =
          menusService.getMenuAvailability(menu);

        if (!menuAvailability) {
          return;
        }
        menu.sections?.forEach(({ items: sectionItems }) => {
          if (sectionItems) {
            items.push(...sectionItems);
          }
        });
      });

      return items;
    });
  }

  @Selector()
  static aboutPage({ aboutPage }: VenueStateModel): any {
    return aboutPage;
  }

  @Selector()
  static contactPage({ contactPage }: VenueStateModel): any {
    return contactPage;
  }

  @Selector()
  static galleryPage({ galleryPage }: VenueStateModel): any {
    return galleryPage;
  }

  @Selector()
  static newsPage({ newsPage }: VenueStateModel): any {
    return newsPage;
  }

  @Selector()
  static newsPageNewsData({ newsPage }: VenueStateModel): Activity[] {
    return (newsPage?.activities || []).filter(
      activity => activity.type === NewsPageDataType.News
    );
  }

  @Selector()
  static newsPageEventsData({ newsPage }: VenueStateModel): Activity[] {
    return (newsPage?.activities || []).filter(
      activity => activity.type === NewsPageDataType.Event
    );
  }

  @Selector()
  static newsPageAllData({ newsPage }: VenueStateModel): Activity[] {
    return newsPage?.activities || [];
  }

  @Selector()
  static galleryPageAllData({ galleryPage }: VenueStateModel): GalleryImage[] {
    return orderBy(galleryPage?.galleryImages || [], 'index', 'asc');
  }

  @Selector()
  static itemInfo({ newsPage }: VenueStateModel): (id: string) => Activity {
    return (id: string): Activity => {
      return (newsPage?.activities || []).find(
        activity => activity.id === id
      ) as Activity;
    };
  }

  @Selector()
  static getFilteringActivitiesValue({ newsPage }: VenueStateModel): string {
    return newsPage?.filteringValue || '';
  }

  @Selector()
  static getFilteringGaleryImagesValue({
    galleryPage,
  }: VenueStateModel): string {
    return galleryPage?.filteringValue || '';
  }

  @Selector()
  static data({ data }: VenueStateModel): any {
    return data;
  }

  @Selector()
  static menus({ menus }: VenueStateModel): Menu[] {
    return menus;
  }

  @Selector()
  static venue({ data }: VenueStateModel): any {
    return data.venue;
  }

  @Selector()
  static venueId({ data }: VenueStateModel): any {
    return data.venue.id;
  }

  @Selector()
  static allItems({ data }: VenueStateModel): Item[] {
    return data.items;
  }

  @Selector()
  static formattedVenueName({ cwName }: VenueStateModel): string {
    return cwName;
  }

  @Selector([VenueState.formattedVenueName])
  static isProd(_: VenueStateModel, formattedVenueName: string): boolean {
    return !!PRODUCTION_VENUE_NAMES.find(name => name === formattedVenueName);
  }

  @Selector([VenueState.formattedVenueName])
  static isEarlyAdopter(
    _: VenueStateModel,
    formattedVenueName: string
  ): boolean {
    return !!EARLY_ADOPTERS_VENUE_NAMES.find(
      name => name === formattedVenueName
    );
  }

  @Selector()
  static availableVisibleMenusItems({
    availableVisibleMenusItems,
  }: VenueStateModel): Item[] {
    return availableVisibleMenusItems;
  }

  @Selector()
  static unavailableItemsIdsWithReason({
    unavailableItemsIdsWithReason,
  }: VenueStateModel): UnavailableItemsIdsWithReason {
    return unavailableItemsIdsWithReason;
  }

  @Selector()
  static unavailableItems({
    unavailableItems,
  }: VenueStateModel): ItemWithMenuAndSection[] {
    return unavailableItems;
  }

  @Selector()
  static venueAddress({ data }: VenueStateModel): Address {
    return data.venue.address;
  }

  @Selector()
  static venueTips({ data }: VenueStateModel): number[] {
    return data.venue.tipPercentages || [];
  }

  @Selector()
  static defaultTipsSelected({ data }: VenueStateModel): number {
    return data.venue.defaultTipsSelected || 0;
  }

  @Selector()
  static visibleMenusIds({ visibleMenusIds }: VenueStateModel): string[] {
    return visibleMenusIds;
  }

  @Selector()
  static isThereAnyAvailableOrderMethod({
    data: {
      venue: { cwSettings, orderOptions },
    },
  }: VenueStateModel): boolean {
    return (
      (cwSettings.orderTypeConfig.delivery.active &&
        orderOptions?.includes(DeliveryMethod.Delivery)) ||
      (cwSettings.orderTypeConfig.takeout.active &&
        orderOptions?.includes(DeliveryMethod.Takeout)) ||
      (cwSettings.orderTypeConfig.curbside.active &&
        orderOptions?.includes(DeliveryMethod.Curbside))
    );
  }

  // @Selector()
  // static isThereAnyAvailableOrderMethod({data: { venue: { cwSettings, orderOptions } }}: VenueStateModel): boolean {
  //   return (
  //     cwSettings.orderTypeConfig.delivery.active ||
  //     cwSettings.orderTypeConfig.takeout.active
  //   ) &&
  //   (
  //     orderOptions.includes(DeliveryMethod.Delivery) ||
  //     orderOptions.includes(DeliveryMethod.Takeout) ||
  //     orderOptions.includes(DeliveryMethod.Curbside)
  //   );
  // }

  static getMenuItemByMenuAndSection(
    itemId: string | undefined,
    menuName: string | undefined,
    sectionName: string | undefined
  ) {
    return createSelector([VenueState], ({ visibleMenus }: VenueStateModel) => {
      if (!itemId || !menuName || !sectionName) {
        return null;
      }

      const menu = visibleMenus.find(menu => menu.menuName === menuName);
      const section = menu?.sections.find(
        section => section.sectionName === sectionName
      );

      return section?.items.find(item => item.itemId === itemId);
    });
  }

  static getMenuIdByItemIdn(itemId: string | undefined) {
    return createSelector([VenueState], ({ visibleMenus }: VenueStateModel) => {
      if (!itemId) {
        return '';
      }

      const menu = visibleMenus.find(
        ({ sections }) =>
          sections?.length &&
          !!sections.find(
            ({ items }) =>
              items?.length && items.find(({ itemId: id }) => id === itemId)
          )
      );

      return menu?.menuId ?? '';
    });
  }

  @Action(GetVenueConfig)
  getVenueConfig(
    { patchState, dispatch }: StateContext<VenueStateModel>,
    { cwName }: GetVenueConfig
  ) {
    const cartVenueId = this.store.selectSnapshot(CartState.venueId);

    return this.service.getVenueConfig(cwName).pipe(
      tap((venue: any) => {
        const state: Partial<VenueStateModel> = {
          cwName: cwName,
          data: venue?.data,
          config: venue?.config,
          websites: venue?.data?.venue?.websites,
          homePage: getPageData(venue?.config, 'home'),
          menuPage: getPageData(venue?.config, 'menu'),
          aboutPage: getPageData(venue?.config, 'about'),
          contactPage: getPageData(venue?.config, 'contact'),
          galleryPage: getPageData(venue?.config, 'gallery'),
          newsPage: getPageData(venue?.config, 'news'),
          qrConfig: venue?.data?.venue?.qrConfig,
        };

        //TODO: remove this when there are real data with ids
        (state.newsPage as NewsPageData).activities = (
          state.newsPage as NewsPageData
        ).activities?.map((activity: any, index: number) => ({
          ...activity,
          id: (index + 1).toString(),
        }));

        state.orderTypes = getPageData(venue?.config, 'home')?.showOrderTypes;

        if (state.menuPage) {
          state.visibleMenusIds = (state.menuPage.visibleMenus || []).map(
            (visibleMenu: any) => visibleMenu.id
          );
          !!state.visibleMenusIds &&
            (state.visibleMenus = getVisibleMenus(
              venue?.data?.menus,
              state.visibleMenusIds
            ));
          !!state.visibleMenus &&
            (state.availableVisibleMenusItems = getAvailableItemsList(
              state.visibleMenus
            ));
          state.menus = getMenusPrepared(venue?.data?.menus || []);
        }

        const actions: any[] = [
          new UpdateVenueOrderSettings(venue?.data?.venue?.unified),
        ];

        state?.data?.venue?.id !== cartVenueId &&
          !this.store.selectSnapshot(CartState.giftCards).length &&
          actions.push(new ClearCart());

        dispatch(actions);
        patchState({ ...state });
        // dispatch(new GetMerchantInformation());
      })
    );
  }

  @Action(CheckItemAvailability)
  checkItemAvailability({
    getState,
    patchState,
  }: StateContext<VenueStateModel>) {
    const visibleMenus: Menu[] = copy(getState()?.visibleMenus);
    const menus = copy(getState()?.data?.menus) as Menu[];
    const cartItemIds = {} as ItemIds;
    cartItemIds.ids = getItemIdsInMenus(visibleMenus);
    cartItemIds.venueId = getState().data?.venue?.id;

    return this.catalogService.getItemAvailability(cartItemIds).pipe(
      tap((allUnavailableItems: ItemUnavailabilityReason[]) => {
        if (allUnavailableItems.length !== 0) {
          // response.length = 0 ==> all items are available
          let unavailableItems: ItemWithMenuAndSection[] = [];
          let unavailableItemsIds: string[] = [];
          let items: ItemWithMenuAndSection[] = [];

          menus.forEach(menu => {
            menu.sections.forEach(section => {
              section.items.forEach(item => {
                items.push({
                  menuId: menu.menuId,
                  sectionName: section.sectionName,
                  ...item,
                });
              });
            });
          });

          const unavailableItemsIdsWithReason = allUnavailableItems.reduce(
            (accum, unavailableItem) => {
              accum[unavailableItem.id] = unavailableItem.unavailabilityReason;
              unavailableItemsIds.push(unavailableItem.id);
              const unavailableItemValue = items.find(
                item => item.itemId === unavailableItem.id
              );

              if (unavailableItemValue) {
                unavailableItems.push(unavailableItemValue);
              }

              return accum;
            },
            {} as Record<string, string>
          );

          patchState({
            unavailableItemsIdsWithReason,
            unavailableItems,
          });
        }
      })
    );
  }

  @Action(GetVenueMenusAvailability)
  getVenueMenusAvailability(
    { patchState, getState }: StateContext<VenueStateModel>,
    { selectedMomentTime }: GetVenueMenusAvailability
  ) {
    const venueMenus = getState().data?.menus ?? [];
    const menuIds: string[] =
      (getState().menuPage?.visibleMenus || [])?.map(
        (visibleMenu: any) => visibleMenu?.id
      ) ?? [];

    patchState({
      menuPage: {
        ...getState()?.menuPage,
        menusAvailability: [],
      },
    });

    menuIds?.forEach(menuId => {
      const venueMenu = venueMenus?.find(
        (venueMenu: any) => venueMenu?.menuId === menuId
      );

      const menusAvailability = getState().menuPage?.menusAvailability;
      const menuAvailability = this.menusService?.getMenuAvailability(
        venueMenu,
        selectedMomentTime
      );

      if (menuAvailability) {
        patchState({
          menuPage: {
            ...getState()?.menuPage,
            menusAvailability: menusAvailability
              ? [...menusAvailability, menuAvailability]
              : [menuAvailability],
          },
        });
      }
    });
  }

  @Action(SetFilteringActivitiesValue)
  setFilteringActivitiesValue(
    { patchState, getState }: StateContext<VenueStateModel>,
    { value }: SetFilteringActivitiesValue
  ) {
    const oldNewsPage = getState().newsPage as NewsPageData;
    const newsPage: NewsPageData = {
      ...oldNewsPage,
      filteringValue: value,
    };

    patchState({ newsPage });
  }

  @Action(SetFilteringGalleryImageValue)
  setFilteringGalleryImageValue(
    { patchState, getState }: StateContext<VenueStateModel>,
    { value }: SetFilteringGalleryImageValue
  ) {
    const oldGalleryPage = getState().galleryPage as GalleryPageData;
    const galleryPage: GalleryPageData = {
      ...oldGalleryPage,
      filteringValue: value,
    };

    patchState({ galleryPage });
  }

  @Action(GetHomePageReviews)
  getHomePageReviews({
    patchState,
    dispatch,
    getState,
  }: StateContext<VenueStateModel>) {
    dispatch(new ShowSpinner());

    const venueId = this.store.selectSnapshot(VenueState.venueId);

    return this.reviewsService.getHomePageReviews(venueId).pipe(
      tap(reviewsResponse => {
        dispatch(new HideSpinner());

        patchState({
          homePage: {
            ...getState().homePage,
            reviews: reviewsResponse.reviews,
          },
        });
      })
    );
  }
}
